import React from 'react';
import _ from 'lodash';

import {markdownify, withPrefix} from '../utils';
import CtaButtons from './CtaButtons';

export default class SectionHero extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section id={_.get(section, 'section_id', null)} className="block block-hero outer home-content" style={{
              backgroundImage: _.get(section, 'hero_image', null) ? `radial-gradient(circle farthest-corner at 10% 20%,  rgba(234,249,249,0.6) 0.1%, rgba(239,249,251,0.7) 90.1%), url(${withPrefix(_.get(section, 'hero_image', null))})` : undefined,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}>
              <div className="hero-content">
                {_.get(section, 'title', null) && (
                <div className="block-header inner-sm">
                  <h1 className="block-title">{_.get(section, 'title', null)}</h1>
                </div>
                )}
                {_.get(section, 'content', null) && (
                <div className="block-content inner-sm">
                  {markdownify(_.get(section, 'content', null))}
                </div>
                )}
                {_.get(section, 'actions', null) && (
                <div className="block-buttons inner-sm">
                  <CtaButtons {...this.props} actions={_.get(section, 'actions', null)} />
                </div>
                )}
              </div>
            </section>
        );
    }
}
